import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBytes',
  standalone: true,
})
export class FormatBytesPipePipe implements PipeTransform {
  transform(bytes: number): string {
    if (isNaN(bytes)) return '';
    if (bytes === 0) return '0 Bytes';
    if (bytes === 1) return '1 Byte';

    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
