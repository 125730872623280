<button
  (click)="activeModal.dismiss()"
  [appFocusable]
  aria-label="Close"
  class="close-btn"
  type="button"
>
  <img
    alt="{{ 'common.buttons.close' | translate }}"
    appAssetSrc="assets/svg/common/cross.svg"
  />
</button>
<files-page
  #filesPage
  [selectMode]="true"
></files-page>
<div class="files-selector-modal_buttons">
  <button
    (click)="activeModal.dismiss()"
    class="btn secondary-btn"
  >
    {{ 'common.buttons.cancel' | translate }}
  </button>
  <button
    (click)="selectFiles()"
    [disabled]="!areFilesSelected"
    class="btn btn-primary submit-btn"
  >
    {{ 'filesPage.buttons.selectFiles' | translate }}
  </button>
</div>
